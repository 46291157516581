import JSBI from 'jsbi'

export const FACTORY_ADDRESS = '0x01a5Ced8e3B189BC08624a5060542Ee4Ea7DcE26'

export const INIT_CODE_HASH = '0xba1680b922a5c093ab1ba5fda33ac03509d75076eb1aa8505ae858d1516da143'

export const MINIMUM_LIQUIDITY = JSBI.BigInt(1000)

// exports for internal consumption
export const ZERO = JSBI.BigInt(0)
export const ONE = JSBI.BigInt(1)
export const FIVE = JSBI.BigInt(5)
export const _997 = JSBI.BigInt(997)
export const _1000 = JSBI.BigInt(1000)
